<template>
  <div id='tickets-screen'
    ref="ticketsScreen"
    :class="{ 'mobile-cart-visible': isMobileCartVisible }" :style="`--mobileMinHeight: ${mobileCartHeight}px`"
  >
    <div class="container hungarospa-container">
      <header>
        <h1 v-if="selectedFacilityName" class="title">{{ selectedFacilityName  }}</h1>
        <span v-if="facilityDescriptionKey" class="description" v-html="$t(facilityDescriptionKey)" />

        <h5 class="subtitle"> {{ $t('pages.tickets.subtitle') }}</h5>
        <ticket-date-picker />
        <error-toast
          :isVisible="errorToast.isVisible"
          :text="errorToast.text"
        />
      </header>
      <div class="content-section hungarospa-two-column-layout">
        <div class="column-left">
          <div class="tickets-table">
            <ticket-row
              v-for="(item, i) in tickets" :key="i"
              :isDateSelected="selectedDateFromStore !== undefined"
              :ticket="item"
              :isBtnLoading="i === isRowBtnLoading.index && isRowBtnLoading.value"
              :hasSecondaryBackground="i % 2 !== 0"
              @addToCartBtnClicked="handleAddToCartBtnClick(item.cikk_id, i, ...arguments)"
            />
          </div>
        </div>
        <div class="column-right">
          <span class="mobile-cart-trigger-btn"
            :class="{ 'animate': isMobileCartBtnAnimate }"
            @click="handleMobileCartBtnClick">
              {{ mobileCartBtnText }}
          </span>
          <div class="mobile-cart-close-btn" @click="handleMobileCartCloseClick"><icon-close :color="'#5E5E5E'" /></div>
          <cart-module ref="cartModule" :class="{ 'mobile-visible': isMobileCartVisible }" @buttonClicked="handleCartBtnClick"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';
import scroll from '@/components/shared/mixins/scroll';
import facilities from '@/components/shared/mixins/facilities';

import TicketDatePicker from '@/components/shared/modules/pickers/TicketDatePicker';
import TicketRow from '@/components/shared/modules/tickets/TicketRow';
import CartModule from '@/components/shared/modules/carts/Cart';

export default {
  name: 'TicketsScreen',
  mixins: [scroll, facilities],
  props: {},
  components: {
    TicketDatePicker,
    TicketRow,
    CartModule,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
    'icon-close': () => import('@/components/shared/elements/icons/IconClose'),
  },
  data: () => ({
    tickets: undefined,
    isRowBtnLoading: {
      index: 0,
      value: undefined,
    },
    isMobileCartVisible: false,
    mobileCartHeight: undefined,
    isMobileCartBtnAnimate: false,
    errorToast: {
      isVisible: false,
      text: undefined,
    },
    selectedFacilityName: undefined,
  }),
  created() {
    this.getCikksFromApi();

    this.getCikkcsoports().then((resp) => {
      this.selectedFacilityName = resp.filter((item) => item.cikkcsop_id === parseInt(this.$route.params.cikkcsoportId, 10))[0].cikkcsop_nev;
    });
  },
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
      getLocalCikkcsoports: 'cikkcsoports/getLocalCikkcsoports',
    }),
    selectedDateFromStore() {
      return store.getters.getSelectedDate;
    },
    mobileCartBtnText() {
      return this.getCurrentCart !== undefined && this.getCurrentCart.price !== undefined
        ? `${this.$t('general.cartModule.title')}: ${this.$options.filters.toCurrency(this.getCurrentCart.price.grossUnitPrice)}`
        : this.$options.filters.toCurrency(0);
    },
    facilityDescriptionKey() {
      if (this.getLocalCikkcsoports) {
        // eslint-disable-next-line eqeqeq
        const cikkcsoport = this.getLocalCikkcsoports.find((item) => item.cikkcsop_id == this.$route.params.cikkcsoportId);

        if (cikkcsoport && cikkcsoport.facilityDescription) {
          if (cikkcsoport.facilityDescription.description && cikkcsoport.facilityDescription.externalLink) {
            return `${cikkcsoport.facilityDescription.description} <br><a target='_blank' href="${cikkcsoport.facilityDescription.externalLink}">${cikkcsoport.facilityDescription.externalLink}</a>`;
          }
          return cikkcsoport.facilityDescription.description;
        }
      }
      return undefined;
    },
  },
  watch: {
    selectedDateFromStore() {
      this.getCikksFromApi();
    },
    getCurrentCart() {
      this.isMobileCartBtnAnimate = true;

      setTimeout(() => {
        this.isMobileCartBtnAnimate = false;
      }, 600);
    },
  },
  methods: {
    ...mapActions({
      getCikks: 'cikks/getCikks',
      addCartItem: 'cart/addCartItem',
      createCart: 'cart/createCart',
      getCikkcsoports: 'cikkcsoports/getCikkcsoports',
    }),
    getCikksFromApi() {
      this.getCikks({
        cikkcsoportId: this.$route.params.cikkcsoportId,
        selectedDate: {
          date: this.$moment(this.selectedDateFromStore).format('YYYY-MM-DD'),
        },
      }).then((resp) => {
        this.tickets = resp;
      });
    },
    addItemToCart(requestObj) {
      const localReqObject = requestObj;
      localReqObject.date = this.selectedDateFromStore;

      this.addCartItem({
        cartId: store.getters.cartId,
        requestObj: localReqObject,
      }).then(() => {
        this.isRowBtnLoading.value = false;
        this.errorToast.isVisible = false;
      }).catch((err) => {
        this.isRowBtnLoading.value = false;
        this.errorToast.isVisible = true;
        this.errorToast.text = err.data.message;

        this.scrollToToast('ticketsScreen');
      });
    },
    handleAddToCartBtnClick(id, ticketIndex, amount) {
      if (this.$FACILITIES.FACILITIES_WITH_WARNING.includes(parseInt(this.$route.params.cikkcsoportId, 10))) {
        this.$eventBus.showFacilityWarningModal();
      }

      this.isRowBtnLoading.index = ticketIndex;
      this.isRowBtnLoading.value = true;

      if (store.getters.cartId === undefined) {
        this.createCart().then(() => {
          this.addItemToCart({
            cikkId: parseInt(id, 10),
            amount,
            ticketIndex,
          });
        });
      } else {
        this.addItemToCart({
          cikkId: parseInt(id, 10),
          amount,
          ticketIndex,
        });
      }
    },
    handleMobileCartBtnClick() {
      this.isMobileCartVisible = true;
      this.mobileCartHeight = this.$refs.cartModule.$el.clientHeight;
    },
    handleMobileCartCloseClick() {
      this.isMobileCartVisible = false;
      this.mobileCartHeight = 0;
    },
    handleCartBtnClick() {
      this.$router.push('/payment/registration');
    },
  },
};
</script>

<style lang='scss' scoped>
$errorToastWidth: 500px;

#tickets-screen {
  padding: 40px 0 70px;
  transition: min-height 0.2s ease-in-out;
  position: relative;
  min-height: 0;

  @media screen and (max-width: $breakpointDownXs) {
    padding: 0;
    margin-bottom: 35px;
  }

  &.mobile-cart-visible {
    @media screen and (max-width: $breakpointDownXs) {
      min-height: var(--mobileMinHeight);
    }
  }
}

header {
  text-align: center;
  margin: 0 0 40px;

  @media screen and (max-width: $breakpointDownXs) {
    margin: 0;
  }

  .title {
    font-size: 40px;
    color: $primaryBlue;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;

    @media screen and (max-width: $breakpointDownSm) {
      font-size: 32px;
    }

    @media screen and (max-width: $breakpointDownXs) {
      display: none;
    }
  }

  .description {
    margin: 15px 0;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;

    @media screen and (max-width: $breakpointDownSm) {
      padding: 0 35px;
      margin: 5px 0 20px;
    }

    @media screen and (max-width: $breakpointDownXs) {
      display: none;
    }
  }

  .subtitle {
    font-size: 12px;
    font-weight: 500;
    margin: 15px 0 20px;

    @media screen and (max-width: $breakpointDownSm) {
      margin: 5px 0 20px;
    }

    @media screen and (max-width: $breakpointDownXs) {
      display: none;
    }
  }
}

.error-toast {
  max-width: $errorToastWidth;
  margin: 35px auto 14px;
}

.tickets-table {
  &::v-deep {
    .ticket-row {
      &:first-child {
        border-radius: $globalBorderRadius $globalBorderRadius 0 0;
      }

      &:last-child  {
        border-radius: 0 0 $globalBorderRadius $globalBorderRadius;
      }
    }
  }
}

.hungarospa-container {
  @media screen and (max-width: $breakpointDownSm) {
    max-width: 100%;
  }

  @media screen and (max-width: $breakpointDownXs) {
    background-color: $white;
  }
}

.hungarospa-two-column-layout {
  @media screen and (max-width: $breakpointDownXl) {
    column-gap: 15px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    row-gap: 20px;
  }
}

.cart-module {
  @media screen and (max-width: $breakpointDownXl) {
    width: 320px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    width: 100%;
  }

  @media screen and (max-width: $breakpointDownXs) {
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleY(0);
    opacity: 0;
    transform-origin: top;
    transition: all 0.4s ease-in-out;

    &.mobile-visible {
      transform: scaleY(1);
      opacity: 1;
    }

    &::v-deep {
      .cart-content {
        background-color: $globalBackgroundColor;
        box-shadow: none;
        border-radius: 0;
        animation: none !important;
      }

      .cart-title {
        display: none;
      }

      .date {
        margin: 0 0 15px;
        display: block;
        padding-left: 0;
        font-size: 17px;
      }

      .cart-item {
        padding: 20px 17px 10px;
        margin: 0 0 25px;
      }

      .left-col {
        font-size: 17px;
      }

      .zone-name {
        line-height: 1.2;
      }

      .price {
        font-size: 18px;
      }

      .summary-row {
        .title {
          font-size: 22px;
          text-transform: uppercase;
          font-weight: 700;;
        }

        .value {
          font-size: 22px;
        }
      }

      .button-row button {
        min-width: 290px;
        height: 45px;
      }
    }
  }

  @media screen and (max-width: $breakpointDownXXs) {
    &::v-deep {
      .date {
        font-size: 15px;
      }

      .left-col {
        font-size: 15px;
      }
    }
  }
}

.mobile-cart-trigger-btn {
  width: 100%;
  background-color: $primaryOrange;
  color: $white;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
  width: calc(100% + 30px);
  margin: 0 -15px;
  cursor: pointer;
  display: none;
  box-shadow: none;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: $breakpointDownXs) {
    display: inline-block;

    &.animate {
      box-shadow: 0 5px 30px rgba($primaryOrange, 1);
    }
  }
}

.mobile-cart-close-btn {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 28px;
  font-weight: 700;
  cursor: pointer;
  transition: opacity 0.4s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;

  @media screen and (max-width: $breakpointDownXs) {
    .mobile-cart-visible & {
      opacity: 1;
      height: auto;
    }
  }

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 24px;
  }
}
</style>
